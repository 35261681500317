<template>
    <div>
        <el-row>
            <el-col>
                <el-form :inline='true'>
                    <el-form-item label="关键字:">
                        <el-input v-model="filters.keyword" @input="(e) => (filters.keyword = utils.validForbid(e))" placeholder="关键字" clearable></el-input>
                    </el-form-item>
                  <el-form-item label="业务类型:">
                    <el-select v-model="filters.filter" clearable multiple style="width: 260px;">
                      <el-option v-for="item in filterArray" :key="item.id" :label="item.value"
                                 :value="item.id"></el-option>
                    </el-select>
                  </el-form-item>
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <!-- 列表 -->
        <el-table :data="tableData" border style="width: 100%" v-loading="listLoading" ref="multipleTable"
            @current-change='currentChange' :highlight-current-row='true' :cell-style="rowClass">
            <el-table-column type='index' label="序号" width='60' align="center" :index='indexMethod'></el-table-column>
            <el-table-column prop="category" label="规范标准" align="center" width="130"></el-table-column>
            <el-table-column prop="name" label="规范名称" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="bn" label="规范编号" align="center" width="200"></el-table-column>
            <el-table-column prop="type" label="规范类型" align="center" width="100"></el-table-column>
            <el-table-column prop="version" label="版本号" align="center" width="120"></el-table-column>
            <el-table-column prop="itemNums" label="条文数量" align="center" width="90"></el-table-column>
            <el-table-column prop="orderSort" label="排序" align="center" width="80"></el-table-column>
            <el-table-column fixed="right" label="操作" align="center" width="130">
                <template slot-scope="scope">
                    <el-button @click="onReview(scope.row)" type="text" size="small">查看</el-button>
                  <el-button type="text" size="small" @click="dialogVisible=true ">添加条文</el-button>

                </template>
            </el-table-column>
        </el-table>
        <AddTiaoWen :dialogTitle="'新增条文编号'" :dialogVisible.sync="dialogVisible"
                    @update:dialogVisible="updateDialogVisibleData" :needData.sync="this.needData"/>
        <!--底部工具条-->
        <el-col :span="24" class="pageBar">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="pages.pageIndex" :page-sizes="pages.pageArr" :page-size="pages.pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="pages.dataCount">
            </el-pagination>
        </el-col>
        <!-- 新建弹窗 -->
        <el-dialog title="新建规范" :visible.sync="addDialogShow" v-model="addDialogShow" :close-on-click-modal="false"
            width='50%'>
            <el-form :model="addForm" :rules="addRules" ref="addFormRef" label-width="160px" style='min-width="800px"'>
                <el-form-item label="规范名称:" prop="name">
                    <el-input v-model="addForm.name"></el-input>
                </el-form-item>
                <el-form-item label="规范编号:" prop="bn">
                    <el-input v-model="addForm.bn"></el-input>
                </el-form-item>
                <el-form-item label="规范标准:" prop="categoryId">
                    <el-select v-model="addForm.categoryId">
                        <el-option v-for="item in categoryOptions" :key="item.id" :label="item.value"
                            :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="规范类型:" prop="typeId">
                    <el-select v-model="addForm.typeId">
                        <el-option v-for="item in typeOptions" :key="item.id" :label="item.value"
                            :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="版本号:">
                    <el-input v-model="addForm.version"></el-input>
                </el-form-item>
                <el-form-item label="排序:">
                    <el-input v-model="addForm.orderSort"></el-input>
                </el-form-item>
	            <el-form-item label="业务类型:">
		            <template>
<!--			            <el-radio-group v-model="addForm.ddIdNodeBizType">-->
<!--				            <el-radio :label="111">图审</el-radio>-->
<!--				            <el-radio :label="253">验收</el-radio>-->
<!--				            <el-radio :label="0">图审+验收</el-radio>-->
<!--			            </el-radio-group>-->
			            <el-checkbox-group v-model="addForm.ddIdNodeBizType">
				            <el-checkbox :label="1">审查</el-checkbox>
				            <el-checkbox :label="10">验收</el-checkbox>
				            <el-checkbox :label="100">开业检查</el-checkbox>
			            </el-checkbox-group>
		            </template>
	            </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="addDialogClose">取消</el-button>
                <el-button type="primary" @click="addSubmit" :loading="addLoading">确认</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import util from "../../../util/date";
import Toolbar from "../../components/Toolbar";
import { getButtonList } from "../../promissionRouter";
import { getDrStandardList, getDrStandardById, addDrStandard, updateDrStandard, deleteDrStandard, getByNamespace } from '@/api/api'
import AddTiaoWen from '../../components/AddTiaoWen.vue'
export default {
    components: { Toolbar , AddTiaoWen},
    data() {
        return {
	        filterArray:[
		        {id: 1,value: "审查"},
		        {id: 10,value: "验收"},
		        {id: 100,value: "开业检查"},
	        ],
            dialogContent:'',
            dialogVisible: false,
            utils: '',
            buttonList: [],
            needData: '',
            filters: {
                keyword: undefined,
                filter:undefined,
            },
            tableData: [],
            listLoading: false,
            //关于分页的obj
            pages: {
                pageSize: 20,//默认每页条数
                pageArr: [10, 20, 30, 40],//默认每页条数选择
                pageIndex: 1, //默认进入页
                dataCount: 20, //默认总页数
            },
            // 新建
            addDialogShow: false,
            addLoading: false,
            addForm: {
                id: undefined,
                categoryId: '',
                name: '',
                bn: '',
                typeId: undefined,
                version: '',
                orderSort: 0,
                isDeleted: false,
	            ddIdNodeBizType:[],
            },
            addRules: {
                categoryId: [{ required: true, message: '请选择规范标准', trigger: 'change' }],
                name: [{ required: true, message: '请输入规范名称', trigger: 'blur' }],
                bn: [{ required: true, message: '请输入规范编号', trigger: 'blur' }],
                typeId: [{ required: true, message: '请选择规范类型', trigger: 'change' }],
                version: [{ required: true, message: '请输入版本号', trigger: 'blur' }],
            },
            typeOptions: [],
            categoryOptions: [] //规范标准
        }
    },
    watch: {

    },
    created() {
        this.utils = util
        let routers = window.localStorage.router
            ? JSON.parse(window.localStorage.router)
            : [];
        this.buttonList = getButtonList(this.$route.path, routers);
        this.getDataList();
        this.init();
    },
    methods: {
      updateDialogVisibleData (value) {
        this.dialogVisible = value; // 更新父组件的数据
      },
        init() {
            // 获取标准
            getByNamespace({ namespace: 'BizService.DrAuditRep.StandardCategory' }).then(res => {
                if (res.data.success) {
                    this.categoryOptions = res.data.response;
                }
            })
            // 获取类型
            getByNamespace({ namespace: 'BizService.DrAuditRep.StandardType' }).then(res => {
                if (res.data.success) {
                    this.typeOptions = res.data.response;
                }
            })
        },
        callFunction(item) {
            if(item.func == "getDataList") {
                this.pages.pageIndex = 1
            }
            this[item.func].apply(this, item);
        },
        // 列表查询接口
        getDataList() {
            let params = {
                pageSize: this.pages.pageSize,
                pageIndex: this.pages.pageIndex,
                keyword: this.filters.keyword,
	            ddIdNodeBizType:undefined,
	            sourceType:'web',
	            }
		        if(this.filters.filter&&this.filters.filter.length>1){
			        params.ddIdNodeBizType = this.arraySum(this.filters.filter)
		        }
		        if (this.filters.filter && this.filters.filter.length === 1) {
			        params.ddIdNodeBizType = this.filters.filter[0]
		        }
            this.listLoading = true
            getDrStandardList(params).then(res => {
                if (res.data.success) {
                    this.tableData = res.data.response.data;
                    this.pages.dataCount = res.data.response.dataCount;
                }
                this.listLoading = false
            })
        },
        // 新建
        handleAdd() {
            this.addDialogShow = true;
            this.addLoading = false;
            this.dialogTitle = '新增';
            this.addForm = {
                id: undefined,
                name: '',
                bn: '',
                typeId: undefined,
                categoryId: undefined,
                version: '',
                orderSort: 0,
                isDeleted: false,
	            ddIdNodeBizType:[],
            }
        },
        addDialogClose() {
            this.addDialogShow = false;
            this.addForm = {
              id: undefined,
              categoryId: '',
              name: '',
              bn: '',
              typeId: undefined,
              version: '',
              orderSort: 0,
              isDeleted: false,
              ddIdNodeBizType: [],
            }
        },
        handleEdit() {
            if (!this.needData.id) {
                this.$message({
                    message: '请选择一条数据',
                    type: "error"
                });
                return
            }
            // getDrStandardById({ id: this.needData.id }).then(res => {
            //     if (res.data.success) {
            //         const { response: data } = res.data;
	          //       console.log(data,'data')
	        console.log(this.needData,'needData')
	          this.addDialogShow = true;
	          this.addLoading = false;
	          this.dialogTitle = '编辑';
	          this.addForm = {
	              id: this.needData.id,
	              name: this.needData.name,
	              bn: this.needData.bn,
	              typeId: this.needData.ddIdStandardType,
	              version: this.needData.version,
	              categoryId: this.needData.ddIdStandardCategory,
	              orderSort: this.needData.orderSort,
	              isDeleted: this.needData.isDeleted,
	            ddIdNodeBizType: []
	          }
	        if (this.needData && this.needData.ddIdNodeBizType === 111 || this.needData.ddIdNodeBizType === 11 || this.needData.ddIdNodeBizType === 110||this.needData.ddIdNodeBizType ===101) {
	          this.addForm.ddIdNodeBizType = this.splitNumberToArray(this.needData.ddIdNodeBizType)
	        }
	        if (this.needData && this.needData.ddIdNodeBizType === 1 || this.needData.ddIdNodeBizType === 10 || this.needData.ddIdNodeBizType === 100) {
	          this.addForm.ddIdNodeBizType.push(this.needData.ddIdNodeBizType)
	        }
                // }
            // })
        },
		    //数组求和
		    arraySum(arr) {
			    return arr.reduce((sum, num) => sum + num, 0);
		    },
		    splitNumberToArray(num) {
			    let numStr = num.toString();
			    let length = numStr.length;
			    let result = [];

			    for (let i = 0; i < length; i++) {
				    let digit = Number(numStr[i]);
				    let placeValue = digit * Math.pow(10, length - i - 1);
				    result.push(placeValue);
			    }

			    return result;
		    },
        // 新建提交
        addSubmit() {
            this.$refs.addFormRef.validate((valid) => {
                if (valid) {
                    this.addLoading = true;
	                let params = {
		                ...this.addForm,
	                }
	                if (this.addForm.ddIdNodeBizType && this.addForm.ddIdNodeBizType.length > 1) {
		                params.ddIdNodeBizType = this.arraySum(this.addForm.ddIdNodeBizType)
	                }
	                if (this.addForm.ddIdNodeBizType && this.addForm.ddIdNodeBizType.length === 1) {
		                params.ddIdNodeBizType = this.addForm.ddIdNodeBizType[0]
	                }
                    if (this.addForm.id === undefined) {
                    addDrStandard(params).then(res => {
                        if (res.data.success) {
                            this.$message({
                                message: res.data.message,
                                type: "success"
                            });
                            this.getDataList();
                            this.addDialogClose();
                        } else {
                            this.$message({
                                message: res.data.message,
                                type: "error"
                            });
                        }
                        this.addLoading = false;
                    })
                    } else {
                        updateDrStandard(params).then(res => {
                            if (res.data.success) {
                                this.$message({
                                    message: res.data.message,
                                    type: "success"
                                });
                                this.getDataList();
                                this.addDialogClose();
                            } else {
                                this.$message({
                                    message: res.data.message,
                                    type: "error"
                                });
                            }
                            this.addLoading = false;
                        })
                    }

                }
            })
        },
        handleDel() {
            if (!this.needData.id) {
                this.$message({
                    message: '请选择一条数据',
                    type: "error"
                });
                return
            }
            this.$confirm('确认删除?', '提示', {
                type: 'warning'
            }).then(() => {
                deleteDrStandard({ id: this.needData.id }).then(res => {
                    if (res.data.success) {
                        this.$message({
                            message: res.data.message,
                            type: "success"
                        });
                        this.getDataList();
                    } else {
                        this.$message({
                            message: res.data.message,
                            type: "error"
                        });
                    }
                })
            })
        },
        // 操作查看
        onReview(val) {
            this.$router.replace({path: "/StandardLibrary/StandardItemManage",query: {queryid: val.id}});
        },
        // 点击table某一行
        currentChange(val) {
            this.needData = val
          console.log(this.needData)
        },
        // 分页
        handleCurrentChange(val) {
            this.pages.pageIndex = val;
            this.getDataList();
        },
        handleSizeChange(val) {
            this.pages.pageSize = val;
            this.getDataList();
        },
      //查询
      getListData(){
        this.pages.pageIndex=1;
        this.getDataList()
      },
        // 时间格式化
        formatCreateTime: function (row, column) {
            return !row.NoticeTime || row.NoticeTime == ""
                ? ""
                : util.formatDate.format(new Date(row.NoticeTime), "yyyy-MM-dd hh:mm");
        },
        rowClass({columnIndex}){
            return 'padding:8px!important;'
        },
        // 表格数据分页条数累计
        indexMethod(index) {
            var currentPage = this.pages.pageIndex
            var pageSize = this.pages.pageSize
            return (index + 1) + (currentPage - 1) * pageSize
        },
    }
}
</script>

<style lang="stylus" scoped>

</style>
